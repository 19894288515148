import React from 'react'
import Layout from '../components/layout'
import Header from '../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../components/Sidebar/Sidebar'

const Sugar = () => (
    <Layout>
      <Helmet>
          <title>IN-ECO Sugar Processing Chemicals</title>
      </Helmet>
      <Header
        styleName='header header-sugar'
        title='Sugar processing chemicals'
        />
      <div className="columns">
        <div className="column content">
          <div className="content-text">The area of interest JSC "Inžinerinė ekologija" includes the development of high-tech solutions and delivery of high-quality materials and modern chemical auxiliary equipment for technological process in the sugar industry:</div>
          <ul>
              <li className="content-text">Highly effective antiscalants for processing sugar syrup evaporator</li>
              <li className="content-text">Disinfection of diffusion installation and preventing of biofouling throughout the production cycle, preventing the formation of dextran, successful control of Leuconostoc bacteria</li>
              <li className="content-text">Organic Defoamers for transportation and washing water and diffusive juice, successfully inhibit and prevent foaming</li>
              <li className="content-text">Deposition and dodge the primary juice using synthetic coagulants and flocculants</li>
              <li className="content-text">Cleaning concentrates for cleaning of process equipment operating at high temperatures</li>
              <li className="content-text">Dosing equipment: pumps, automatic dosing installations</li>
          </ul>
        </div>
        <Sidebar
          analysis='Questionnaires'
          boiler='Boiler'
          cooling='Cooling'
          ro='RO'
          broshure='IN-ECO brochure'
          certificates='Certificates'
        />
      </div>
    </Layout>
)

export default Sugar